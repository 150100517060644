import { Container, Link } from "@mui/material";
import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container component="main">
      <div id="contact" class="form">
        <div class="container">
          <div class="row my-3">
            <Link
              component={RouterLink}
              to={"/"}
              className="col-lg-12 text-center"
            >
              <img
                src="images/logo/Asset 9 2.png"
                alt="policy"
                className="desk_tops"
              />
            </Link>
            <div class="col-lg-12">
              <h2 className="mb-3">Privacy Policy</h2>
              <ul class="list-unstyled li-space-lg">
                <li class="address">
                  Last Updated: Friday 11<sup>th</sup> August 2023
                </li>
              </ul>
            </div>
            <div class="col-lg-12 pb-3 text-wrapper">
              <p>
                Thank you for using Carib Bargains, the Apps and the website.
                This Privacy Policy outlines how we collect, use, and protect
                the information you provide when using our services. By
                accessing or using the App or Website, you agree to the terms of
                this Privacy Policy.
              </p>
            </div>
            <div class="col-lg-12 pb-3 text-wrapper">
              <p className="mb-2">
                <b>Information We Collect:</b>
              </p>
              <ol type="1">
                <li>
                  <p className="mb-2">
                    <b>Personal Information:</b> We may collect personal
                    information you provide during registration, such as your
                    name, email address, phone number, and location data.
                  </p>
                </li>
                <li>
                  <p className="mb-2">
                    <b>User-Generated Content:</b> The App/Website may collect
                    user-generated content such as listings, messages, reviews,
                    and images that you submit.
                  </p>
                </li>
                <li>
                  <p className="mb-2">
                    <b>Device Information:</b> We may collect information about
                    your device, including the device type, operating system,
                    unique device identifiers, and IP address.
                  </p>
                </li>
                <li>
                  <p className="mb-2">
                    <b>Usage Data:</b> We collect data about how you use the
                    App/Website, including pages visited, actions taken, and
                    interactions with other users.
                  </p>
                </li>
              </ol>
            </div>
            <div class="col-lg-12 pb-3 text-wrapper">
              <p className="mb-2">
                <b>How We Use Your Information:</b>
              </p>
              <ol type="1">
                <li>
                  <p className="mb-2">
                    <b>To Provide Services:</b> We use the information you
                    provide to offer our services, facilitate communication
                    between users, and process transactions.
                  </p>
                </li>
                <li>
                  <p className="mb-2">
                    <b>Improvement and Analytics:</b> We analyze usage data to
                    improve our services, enhance user experience, and make
                    informed decisions.
                  </p>
                </li>
                <li>
                  <p className="mb-2">
                    <b>Marketing and Communications: </b> We may send you
                    notifications, updates, and promotional messages related to
                    our services. You can opt-out of marketing communications at
                    any time.
                  </p>
                </li>
                <li>
                  <p className="mb-2">
                    <b>Legal Compliance:</b> We may use your information to
                    comply with legal obligations, respond to legal requests,
                    and enforce our terms of service.
                  </p>
                </li>
              </ol>
            </div>
            <div class="col-lg-12 pb-3 text-wrapper">
              <p className="mb-2">
                <b>Information Sharing:</b>
              </p>
              <ol type="1">
                <li>
                  <p className="mb-2">
                    <b>Third Parties:</b> We may share your information with
                    trusted third-party service providers (list is given below
                    under section third parties) who assist us in delivering our
                    services (e.g., payment processors, analytics tools).
                  </p>
                </li>
                <li>
                  <p className="mb-2">
                    <b>Legal Requirements:</b> We may disclose your information
                    when required by law or to protect our rights, users'
                    safety, or the public.
                  </p>
                </li>
              </ol>
            </div>
            <div class="col-lg-12 pb-3 text-wrapper">
              <p className="mb-2">
                <b>Data Security:</b>
              </p>
              <p className="mb-2">
                We take reasonable measures to protect your information from
                unauthorized access, disclosure, alteration, or destruction.
                However, no data transmission over the internet or storage
                system is entirely secure.
              </p>
              <p className="mb-2">
                <b>GDPR Compliance:</b>
              </p>
              <p className="mb-2">
                Our app is not targeted at users within the European Union (EU),
                and we do not intentionally collect personal data from
                individuals within the EU. As a result, the General Data
                Protection Regulation (GDPR) does not apply to our app's data
                practices. We have already mentioned how we will be using your
                personal data and its security above.
              </p>
              <p className="mb-2">
                <b>COPPA Compliance:</b>
              </p>
              <p className="mb-2">
                <p className="mb-2">Intended Audience and Age Requirement: </p>{" "}
                COPPA Compliance not needed as our app is intended for users
                aged 13 and older. By using our app, you confirm that you are at
                least 13 years of age.
              </p>
              <p className="mb-2">
                You can access, modify your personal information using settings
                page, or delete your personal information by contacting us using
                email and website form given at the end of this page.{" "}
              </p>
              <p className="mb-2">
                <b>Third-Party Used:</b>
              </p>
              <p className="mb-2">
                The App/Website uses following third-party websites or services
                to be able to make it functional and to analyze usage data to
                improve our services, enhance user experience, and make informed
                decisions.{" "}
              </p>
              <p className="mb-2">
                <b>These include:</b>
              </p>
              <ol type="1">
                <li>
                  <p>Facebook, Google & Apple for Authentication </p>
                </li>
                <li>
                  <p>Google for Maps</p>
                </li>
                <li>
                  <p>Firebase for Push Notifications, OTPs & Analytics</p>
                </li>
              </ol>
              <p className="mb-2">
                <b>External User Posted/ Third-Party Links: </b>
              </p>
              <p className="mb-2">
                The App/Website may contain links to third-party websites or
                services. We are not responsible for the practices, content, or
                privacy policies of these third parties.
              </p>
              <p className="mb-2">
                <b>In-App Permissions:</b>
              </p>
              <p className="mb-2">
                The App/Website uses following third-party websites or services
                to be able to make it functional and to analyze usage data to
                improve our services, enhance user experience, and make informed
                decisions.{" "}
              </p>
              <p className="mb-2">
                <b>These include:</b>
              </p>
              <ol type="1">
                <li>
                  <p className="mb-2">
                    {" "}
                    <b>Camera & Gallery:</b> For photos for user’s profile and
                    product or service
                  </p>
                </li>
                <li>
                  <p className="mb-2">
                    {" "}
                    <b>Location:</b> For current location of user and for ads
                    being posted
                  </p>
                </li>
                <li>
                  <p className="mb-2">
                    {" "}
                    <b> Push Notifications:</b> For receiving app notifications
                    for information, alerts, promotion and upgrade of
                    membership.
                  </p>
                </li>
              </ol>
              <p className="mb-2">
                <b>Changes to this Privacy Policy:</b>
              </p>
              <p className="mb-2">
                We may update this Privacy Policy from time to time to reflect
                changes in our practices or legal requirements. We encourage you
                to review this policy periodically.
              </p>
              <p className="mb-2">
                <b>Contact Us:</b>
              </p>
              <p className="mb-2">
                If you have any questions or concerns about this Privacy Policy,
                please contact us at:
              </p>

              <p className="mb-2">
                <b>Email:</b>{" "}
                <a href="mailto: mailto:support@caribbargains.com">support </a>{" "}
              </p>
              <p className="mb-2">
                <b>Website:</b>{" "}
                <a href="https://www.caribbargains.com">
                  {" "}
                  https://www.caribbargains.com/
                </a>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
