import React, { useState, useEffect } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Payment = ({
  handleClose,
  price,
  saveOrderHandler,
  isTopup,
  isAdPost,
}) => {
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);
  let navigate = useNavigate();
  // creates a paypal order
  const createOrder = (data, actions) => {
    let numberAmount;
    if (isTopup) {
      const stringValue = price;
      numberAmount = parseFloat(stringValue.replace("$", ""));
    } else {
      numberAmount = price;
    }
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Cart items for testings",
            amount: {
              currency_code: "USD",
              value: numberAmount,
            },
          },
        ],
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });
  };

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      // const { payer,status,id } = details;
      saveOrderHandler(details);
      handleClose();
      if (isAdPost) {
        return;
      } else {
        setTimeout(() => {
          if (isTopup) {
            navigate("/my-profile", { state: { walletScreen: true } });
          } else {
            navigate("/my-profile", { state: { subscriptionScreen: true } });
          }
        }, 3000);
      }
    });
  };

  //capture likely error
  const onError = (data, actions) => {
    setErrorMessage("An Error occurred with your payment ");
  };

  useEffect(() => {
    if (success) {
      setErrorMessage("Payment successful!");
    }
  }, [success]);

  return (
    <>
      {/* <PayPalScriptProvider options={{ "client-id": "AYWhyk7whGF6vq6IMXlLbC7JibL5gorfAyE0FbWYnFUqk8LevyCW_1dQ3Lc6PCctORnXeR8j7MUGIekA" }}> */}
      <PayPalScriptProvider
        options={{
          "client-id":
            "AVIytk02OCBn-ewbEtBrCeMq_lOhmlCtoHUyaM-nPCOImkYhxkXKjei6aJgpRbceOCuUhOqPCRp1uQZH",
        }}
      >
        <div className="wrapper">
          <PayPalButtons
            style={{ layout: "horizontal" }}
            createOrder={createOrder}
            onApprove={onApprove}
          />
        </div>
      </PayPalScriptProvider>
    </>
  );
};

export default Payment;
